<template>
    <div v-if="isShown" class="wrapper-popup-cookies">
        <div class="popup-cookies">
            <h3>Cookie Policy</h3>
            <p>{{ $t("cookiesPolicyBox.text") }}</p>
            <div class="controls-cookies">
                <span v-on:click="accept">{{ $t("cookiesPolicyBox.btnText") }}</span>
                <router-link to="privacy-policy">{{ $t("cookiesPolicyBox.moreInfoText") }}</router-link>
            </div>
        </div>
    </div>
</template>
<script>
import { fbChat } from './../mixins/fb-chat.mixin';
export default {
    mixins:[ fbChat ],
    data() {
        return {
            isShown: false,
            localStorageKey: 'cookies-policy-confirmed'
        }
    },
    methods: {
        accept() {
            localStorage.setItem(this.localStorageKey, '1');
            this.isShown = false;
        }
    },
    mounted() {
        this.isShown = localStorage.getItem(this.localStorageKey) != '1';
    }
}
</script>
<style lang="scss" scoped>
@import './../scss/app';

.wrapper-popup-cookies {
    position: fixed;
    bottom: 20px;
    width: 25%;
    left: 34px;
    right: 25%;
    height: 120px;
    z-index: 9997;
}

.popup-cookies {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px 20px;
    background: rgba(255,255,255, 0.9); 
    margin: auto;
    border-radius: 10px;
    border: 3px solid rgb(73, 35, 216);
    p {
        padding-top: 15px;
        font-family: $family-light;
        font-weight: 500;
    }
}

.controls-cookies {
    padding-top: 15px;
    span {
        display: inline-block;
        cursor: pointer;
        padding: 5px 30px;
        border: 1px solid rgba(22, 20, 20, 0.116);
        border-radius: 5px;
        color: #fff;
        font-family: $family-bold;
        font-weight: 600;
        background:rgba(73, 35, 216, .9);

        &:hover {
            background:rgba(73, 35, 216, 1);
        }
    }

    a {
        display: inline-block;
        padding-left: 10px;
        color: #000;
        font-family: $family-light;
        font-weight: 500;
    }
}

@media (max-width: 992px) {
    .wrapper-popup-cookies {
        width: 100%;
        left:0;
        right:0;
        bottom: 0;
    }
    
    .popup-cookies {
        border-radius: 0px;
    }
}


@media (max-width: 576px) {
    
}

</style>
